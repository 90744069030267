import * as React from 'react';
import Layout from 'src/components/Layout';
import Header from "src/components/Header";
import FormFilling from "src/components/FormFilling";
import Footer from 'src/components/Footer';

const FormFillingPage = () => (
  <Layout>
    <Header fixColor />
    <FormFilling />
    <Footer />
  </Layout>
);

export default FormFillingPage;
