import React, { CSSProperties, forwardRef, memo } from 'react';
import cn from 'classnames';

import Text from 'src/components/ui/Text';

import * as styles from './Tooltip.module.scss';

type Props = {
  isShown?: boolean;
  style?: CSSProperties;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Tooltip = forwardRef<HTMLDivElement, Props>(({ isShown = false, children, style, className }, ref) => (
  <div
    className={cn(
      styles.tooltip,
      className,
      {
        [styles.tooltip_visible]: isShown,
      }
    )}
    ref={ref}
    style={style}
  >
    <Text element='p' className={styles.tooltip__text}>{children}</Text>
  </div>
))

export default memo(Tooltip);
