// extracted by mini-css-extract-plugin
export var formFilling = "FormFilling-module--formFilling--Ev97o";
export var formFilling__wrapper = "FormFilling-module--formFilling__wrapper--1FGR2";
export var formFilling__wrapper_active = "FormFilling-module--formFilling__wrapper_active--DqxuU";
export var formFilling__title = "FormFilling-module--formFilling__title--2r7Ql";
export var formFilling__text = "FormFilling-module--formFilling__text--1QRop";
export var formFilling__form = "FormFilling-module--formFilling__form--1-q-w";
export var formFilling__personalText = "FormFilling-module--formFilling__personalText--Vs7_1";
export var formFilling__highlight = "FormFilling-module--formFilling__highlight--1FKa7";
export var formFilling__label = "FormFilling-module--formFilling__label--3U9q6";
export var formFilling__personalInformation = "FormFilling-module--formFilling__personalInformation--yhcUj";
export var formFilling__footer = "FormFilling-module--formFilling__footer--1P069";
export var formFilling__columnFooter = "FormFilling-module--formFilling__columnFooter--1EtPT";
export var formFilling__link = "FormFilling-module--formFilling__link--uJ7j_";
export var formFilling__btn = "FormFilling-module--formFilling__btn--1Zi48";
export var formFilling__columnBtn = "FormFilling-module--formFilling__columnBtn--pWvNo";