import * as styles from './FormFilling.module.scss';

import React, {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import Input from 'src/components/ui/Input';
// @ts-ignore
import usePhoneFormatter from 'src/scripts/usePhoneFormatter';
import Button from "src/components/ui/Button";
import Popup from "src/components/ui/Popup";
import Tooltip from "src/components/ui/Tooltip";
import PopupPersonalData from "src/components/PopupPersonalData";

  const form = {
    terminalkey: '1622616579892',
    frame: 'true',
    language: 'ru',
    amount: '1',
    order: new Date().getTime(),
    description: 'Информационные услуги',
  }

const PRICE = '29000';

const FormFilling: React.FC = () => {
    const [isOpenPolicy, setIsOpenPolicy] = useState(false);
    const [isOpenOffer, setIsOpenOffer] = useState(false);
    const [isOpenToolTipOffer ,setIsOpenToolTipOffer] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [telephone, setTelephone] = useState('');
    const { onPhoneKeyDown, onPhoneInput, onPhonePaste } = usePhoneFormatter();
    const [isMounted, setIsMounted] = useState(false);
    const [isPayment, setIsPayment] = useState(false);
    const Tinkoff = useRef<any>(null);

    const receipt = {
      Email: email,
      Taxation: 'usn_income',
      Items: [
        {
          Name: form.description,
          Price: +PRICE * 100,
          Quantity: 1,
          Amount: +PRICE * 100,
          PaymentMethod: 'full_payment',
          PaymentObject: 'service',
          Tax: 'none',
        },
      ],
    }

    useEffect(() => {
      if(window.location.hash === '#popupOffer') {
        setIsOpenOffer(true);
      }
      if (typeof window !== 'undefined') {
        // @ts-ignore
        import('react-tinkoff-pay').then((d) => {
          Tinkoff.current = d.default;
          setIsMounted(true);
        });
      }
    }, []);

    const onClosePolicyPopup = useCallback(() => {
      setIsOpenPolicy(false);
      if (window.location.hash === '#popupPolicy') location.href = window.location.origin + window.location.pathname
    }, []);
    const onClosePolicyOffer = useCallback(() => {
      setIsOpenOffer(false);
      if(window.location.hash === '#popupOffer') location.href = window.location.origin + window.location.pathname
    }, []);

    const onSubmit = (e: FormEvent) => {
      e.preventDefault();
      setIsPayment(true);
    };

  return (
    <section className={styles.formFilling} id="form">
        <div className={styles.formFilling__wrapper}>
          <h2 className={styles.formFilling__title}>Заполните форму, чтобы оформить заказ</h2>
          <form className={styles.formFilling__form} onSubmit={onSubmit}>
            <label className={styles.formFilling__label}>
              <span className={styles.formFilling__personalInformation}>Введите ваш Email адрес</span>
              <Input value={email} onChange={setEmail} placeholder="E-mail" />
            </label>
            <label className={styles.formFilling__label}>
              <span className={styles.formFilling__personalInformation}>Как Вас зовут?</span>
              <Input value={name} onChange={setName} placeholder="Ваше имя" />
            </label>
            <label className={styles.formFilling__label}>
              <span className={styles.formFilling__personalInformation}>Телефон</span>
              <Input
                value={telephone}
                onChange={setTelephone}
                placeholder="Номер телефона"
                onInput={onPhoneInput}
                onKeyDown={onPhoneKeyDown}
                onPaste={onPhonePaste}
              />
            </label>
            <label className={styles.formFilling__label}>
              <span className={styles.formFilling__personalInformation}>Цена курса</span>
              <Input value={`${PRICE} руб.`} readOnly />
            </label>
            <div className={styles.formFilling__footer}>
              <p
                className={styles.formFilling__personalText}
              >
                Нажимая на кнопку, я соглашаюсь на
                {' '}
                <a
                  className={styles.formFilling__link}
                  href="#popupPolicy"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpenPolicy(true);
                  }}
                >
                  обработку персональных данных
                </a>
                {' '}и{' '}
                <a
                  className={styles.formFilling__link}
                  href="#popupOffer"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpenOffer(true);
                  }}
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    setIsOpenToolTipOffer(true);
                  }}
                  onMouseLeave ={(e) => {
                    e.preventDefault();
                    setIsOpenToolTipOffer(false);
                  }}
                >
                  договор оферты
                  <Tooltip isShown={isOpenToolTipOffer}>
                    <p>Настоящий договор о предоставлении информационно-консультационных услуг по направлению UX-дизайна является официальным предложением (публичной офертой) адресованным любому физическому лицу, в дальнейшем именуемому Заказчик, согласившемуся с условиями настоящей публичной оферты, путем ее полного и безоговорочного акцепта.</p>
                    <p>Заказчик обязан полностью ознакомиться с настоящим документом до момента внесения оплаты.</p>
                  </Tooltip>
                </a>
              </p>
              <Button
                className={styles.formFilling__btn}
                disabled={!/(.+)@(.+)/.test(email) || !name || !telephone || isNaN(+PRICE)}
              >
                Оплатить
              </Button>
            </div>
          </form>

          {isMounted && isPayment && <Tinkoff.current.Pay form={{ ...form, name, email, phone: telephone, amount: PRICE, receipt: JSON.stringify(receipt) }} onClose={() => setIsPayment(false)} />}

          <Popup onClose={onClosePolicyPopup} open={isOpenPolicy}>
            <PopupPersonalData type={0} />
          </Popup>
          <Popup onClose={onClosePolicyOffer} open={isOpenOffer}>
            <PopupPersonalData type={1} />
          </Popup>
        </div>
    </section>
  )
}

export default React.memo(FormFilling);
